<template>
  <div>
    <el-card shadow="never">
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
               @submit.native.prevent>
        <el-form-item label="手机号:" label-width="90px" style="margin-right: 10px;">
          <el-input v-model="phone" placeholder="请输入手机号" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
        </el-form-item>
      </el-form>
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="PhoneNum" label="手机号" show-overflow-tooltip min-width="120"/>
          <el-table-column label="发送状态" show-overflow-tooltip min-width="120">
            <template slot-scope="{row}">{{ row.SendStatus == 1 ? '等待回执' : (row.SendStatus == 2 ? '发送失败' :
              (row.SendStatus == 3 ? '发送成功' : ''))}}
            </template>
          </el-table-column>
          <el-table-column prop="TemplateCode" label="短信模板ID" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="Content" label="短信内容" align="center" show-overflow-tooltip min-width="400"/>
          <el-table-column label="短信状态码" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="{row}">
               
              {{arr[row.ErrCode][0]}}<br>
              {{arr[row.ErrCode][1]}}<br>
              {{arr[row.ErrCode][2]}}<br>
            </template>
          </el-table-column>
          <el-table-column prop="SendDate" label="发送时间" show-overflow-tooltip min-width="160"/>
          <el-table-column prop="ReceiveDate" label="接收时间" show-overflow-tooltip min-width="160"/>
        </template>
        <el-pagination class="ele-pagination-circle"/>
      </ele-data-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "messageQuery",
  data() {
    return {
      arr: {
        'OK':['OK','OK','表示接口调用成功。'],
        'DELIVERED':['DELIVERED','发送成功',''],
        'isv.SMS_SIGNATURE_SCENE_ILLEGAL':['isv.SMS_SIGNATURE_SCENE_ILLEGAL','签名和模板类型不一致','原因：模板和签名类型不一致，例如用验证码签名下发了通知短信或短信推广。解决方案：签名分为验证码和通用两种类型。模板分为验证码、短信通知和推广短信三种类型，其中验证码类签名只能发送验证码模板，通用类签名可以发送全部类型的模板。建议您把签名更改为“通用”类型。'],
        'isv.EXTEND_CODE_ERROR':['isv.EXTEND_CODE_ERROR','扩展码使用错误，相同的扩展码不可用于多个签名','原因：发送短信时，不同签名的短信使用了相同扩展码。解决方案：在调用短信发送接口时，不同的短信签名使用不同的扩展码。'],
        'isv.DOMESTIC_NUMBER_NOT_SUPPORTED':['isv.DOMESTIC_NUMBER_NOT_SUPPORTED','国际/港澳台消息模板不支持发送境内号码','原因：国际/港澳台消息模板仅支持发送国际、中国港澳台地区的号码。解决方案：如果要发送中国内地短信，请申请国内消息短信模板。'],
        'isv.DENY_IP_RANGE':['isv.DENY_IP_RANGE','源IP地址所在的地区被禁用','原因：被系统检测到源IP属于非中国内地地区。解决方案：请将源IP地址修改为中国内地地区的IP地址。国际/港澳台的IP地址禁止发送中国内地短信业务。'],
        'isv.DAY_LIMIT_CONTROL':['isv.DAY_LIMIT_CONTROL','触发日发送限额','原因：已经达到您在控制台设置的短信日发送量限额值。解决方案：如需修改限额，请登录短信服务控制台，在左侧导航栏，选择系统设置 > 国内消息设置 > 安全设置，修改日发送量总量阈值。'],
        'isv.MONTH_LIMIT_CONTROL':['isv.MONTH_LIMIT_CONTROL','触发月发送限额','原因：已经达到您在控制台设置的短信月发送量限额值。解决方案：如需修改限额，请登录短信服务控制台，在左侧导航栏，选择系统设置 > 国内消息设置 > 安全设置，修改月发送量总量阈值。'],
        'isv.SMS_CONTENT_ILLEGAL':['isv.SMS_CONTENT_ILLEGAL','短信内容包含禁止发送内容','原因：短信内容包含禁止发送内容。解决方案：修改短信文案。'],
        'isv.SMS_SIGN_ILLEGAL':['isv.SMS_SIGN_ILLEGAL','签名禁止使用','原因：签名禁止使用。解决方案：请在短信服务控制台申请符合规定的签名。更多操作，请参见添加签名。'],
        'isp.RAM_PERMISSION_DENY':['isp.RAM_PERMISSION_DENY','RAM权限不足','原因：RAM权限不足。解决方案：请为当前使用的AccessKey对应RAM用户进行授权：AliyunDysmsFullAccess（管理权限）。具体操作请参见授权RAM用户。'],
        'isv.OUT_OF_SERVICE':['isv.OUT_OF_SERVICE','业务停机','原因：余额不足。业务停机时，套餐包中即使有短信额度也无法发送短信。解决方案：请及时充值。'],
        'isv.PRODUCT_UN_SUBSCRIPT':['isv.PRODUCT_UN_SUBSCRIPT','未开通云通信产品的阿里云客户 ','原因：该AccessKey所属的账号尚未开通云通信的服务，包括短信、语音、流量等服务。解决方案：当出现此类报错信息时，需要检查当前AccessKey是否已经开通阿里云短信服务。如已开通短信服务，则参照短信服务文档调用接口。'],
        'isv.PRODUCT_UNSUBSCRIBE':['isv.PRODUCT_UNSUBSCRIBE','产品未开通 ','原因：该AccessKey所属的账号尚未开通当前接口的产品，例如仅开通了短信服务的用户调用语音服务接口时会产生此报错信息。解决方案：检查AccessKey对应账号是否已开通调用对应接口的服务。如需开通服务，请参见短信服务或语音服务。'],
        'isv.ACCOUNT_NOT_EXISTS':['isv.ACCOUNT_NOT_EXISTS','账户不存在','原因：使用了错误的账户名称或AccessKey。解决方案：请确认账号信息。'],
        'isv.ACCOUNT_ABNORMAL':['isv.ACCOUNT_ABNORMAL','账户异常','原因：账户异常。解决方案：计费服务查询异常，请点击链接，打开钉钉扫码进入短信服务专属钉群。'],
        'isv.SMS_TEMPLATE_ILLEGAL':['isv.SMS_TEMPLATE_ILLEGAL','该账号下找不到对应模板','原因：在您的账号下找不到对应模板，可能AccessKey账号和模板归属于不同账号，或使用了未审核通过的模板。解决方案：请检查您的AccessKey账号和模板是否属于同一个账号，或登录短信服务控制台，在模板管理页面查此模板是否审核通过。'],
        'isv.SMS_SIGNATURE_ILLEGAL':['isv.SMS_SIGNATURE_ILLEGAL','该账号下找不到对应签名','原因：在您的账号下找不到对应编号的签名，可能是AccessKey账号和签名归属于不同账号，或使用了未审核通过的签名。您传入的签名有空格、问号、错别字等导致乱码。解决方案：请检查您的AccessKey和签名是否属于同一个账号，或登录短信服务控制台，在签名管理页面查看此签名是否审核通过。请检查您传入的签名格式是否正确，删除签名中的空格、特殊符号，修改错别字。签名规范请参见短信签名规范。'],
        'isv.INVALID_PARAMETERS':['isv.INVALID_PARAMETERS','参数格式不正确','原因：参数格式不正确。解决方案：请根据对应的API文档检查参数格式。例如，短信查询QuerySendDetails接口的参数SendDate日期格式为yyyyMMdd，正确格式为20170101，错误格式为2017-01-01。'],
        'isp.SYSTEM_ERROR':['isp.SYSTEM_ERROR','系统出现错误，请重新调用','原因：系统出现错误。解决方案：请重新调用接口。'],
        'isv.MOBILE_NUMBER_ILLEGAL':['isv.MOBILE_NUMBER_ILLEGAL','手机号码格式错误','原因：手机号码格式错误。解决方案：参数PhoneNumbers请传入正确的格式。国内消息：+/+86/0086/86或无任何前缀的11位手机号码，例如1595195****。国际/港澳台消息：国际区号+号码，例如8520000****。'],
        'isv.MOBILE_COUNT_OVER_LIMIT':['isv.MOBILE_COUNT_OVER_LIMIT','手机号码数量超过限制，最多支持1000条','原因：参数PhoneNumbers中指定的手机号码数量超出限制。解决方案：请将手机号码数量限制在1000个以内。'],
        'isv.TEMPLATE_MISSING_PARAMETERS':['isv.TEMPLATE_MISSING_PARAMETERS','模板变量中存在未赋值变量','原因：参数TemplateParam中，变量未全部赋值。解决方案：请用JSON格式字符串为模板变量赋值。例如，模板为您好${name}，验证码${code}，则参数TemplateParam可以指定为{"name":"Tom","code":"123"}。'],
        'isv.BUSINESS_LIMIT_CONTROL':['isv.BUSINESS_LIMIT_CONTROL','触发云通信流控限制','原因：达到云通信短信发送频率上限。解决方案：请您将短信发送频率限制在正常的业务流控范围内，默认流控详情，请参见短信发送频率限制。您还可登录短信服务控制台，在系统设置 > 国内消息设置页面调整流控阈值。具体操作，请参见设置短信发送频率和频率限制白名单。'],
        'isv.INVALID_JSON_PARAM':['isv.INVALID_JSON_PARAM','参数格式错误，请修改为字符串值','原因：参数格式错误，不是合法的JSON格式，修改为字符串值。解决方案：请在参数TemplateParam中指定正确的JSON格式字符串，例如{"code":"123"}。'],
        'isv.BLACK_KEY_CONTROL_LIMIT':['isv.BLACK_KEY_CONTROL_LIMIT','黑名单管控','原因：黑名单管控是指短信号码命中黑名单，此类号码曾有过退订或投诉记录，不支持下发该类推广短信。解决方案：推广短信建议规避该号码下发。'],
        'isv.PARAM_LENGTH_LIMIT':['isv.PARAM_LENGTH_LIMIT','参数超过长度限制','原因：参数超过长度限制。解决方案：针对2018年01月10日之后申请的短信通知类模板，变量限制为1~35个字符；验证码类模板，变量限制为4～6个字符，请修改参数长度。'],
        'isv.PARAM_NOT_SUPPORT_URL':['isv.PARAM_NOT_SUPPORT_URL','变量不支持传入URL','原因：变量内容中含有限制发送的内容，例如变量中不允许透传URL。解决方案：请检查通过变量是否透传了URL或敏感信息。短信模板内容规范，请参见公共内容规范。'],
        'isv.AMOUNT_NOT_ENOUGH':['isv.AMOUNT_NOT_ENOUGH','账户余额不足','原因：当前账户余额不足。解决方案：请及时充值。调用接口前请确认当前账户余额是否足以支付预计发送的短信量。'],
        'isv.TEMPLATE_PARAMS_ILLEGAL':['isv.TEMPLATE_PARAMS_ILLEGAL','传入的变量内容和实际申请模板时变量所选择的属性类型不配 ','原因：例如申请模板时对phone变量，选择变量属性为"电话号码"，但实际入参时对这个变量赋值非号码类型的内容。解决方案：针对上述原因，phone变量入参应为：5-11位的国内标准手机号或固定电话号码，更多变量属性规范，请参见验证码模板变量属性规范或通知短信模板变量属性规范。'],
        'SignatureDoesNotMatch':['SignatureDoesNotMatch','客户端生成的签名与服务端不匹配','原因：签名（Signature）加密错误。解决方案：如果使用SDK调用接口，请注意AccessKey ID和AccessKey Secret字符串赋值正确。如果自行加密签名（Signature），请参见请求签名检查加密逻辑。'],
        'InvalidTimeStamp.Expired':['InvalidTimeStamp.Expired','时间戳或日期已过期','原因：一般由于时区差异造成时间戳错误，发出请求的时间和服务器接收到请求的时间不在15分钟内。解决方案：请使用GMT时间。说明 阿里云网关使用的时间是GMT时间。'],
        'SignatureNonceUsed':['SignatureNonceUsed','签名随机数已被使用','原因：唯一随机数重复，SignatureNonce为唯一随机数，用于防止网络重放攻击。解决方案：不同请求请使用不同的随机数值。'],
        'InvalidVersion':['InvalidVersion','API版本号错误','原因：版本号（Version）错误。解决方案：请确认接口的版本号，短信服务的API版本号（Version）为2017-05-25。'],
        'InvalidAction.NotFound':['InvalidAction.NotFound','未找到指定的API，请检查您的URL和方法','原因：参数Action中指定的接口名错误。解决方案：请在参数Action中使用正确的接口地址和接口名。'],
        'isv.SIGN_COUNT_OVER_LIMIT':['isv.SIGN_COUNT_OVER_LIMIT','超过单自然日签名申请数量上限','原因：一个自然日中申请签名数量超过限制。解决方案：合理安排每天的签名申请数量，次日重新申请。更多信息，请参见个人用户和企业用户权益区别。'],
        'isv.TEMPLATE_COUNT_OVER_LIMIT':['isv.TEMPLATE_COUNT_OVER_LIMIT','超过单自然日模板申请数量上限','原因：一个自然日中申请模板数量超过限制。解决方案：合理安排每天的模板申请数量，次日重新申请。更多信息，请参见个人用户和企业用户权益区别。'],
        'isv.SIGN_NAME_ILLEGAL':['isv.SIGN_NAME_ILLEGAL','签名名称不符合规范','原因：签名名称不符合规范。解决方案：请重新申请签名。签名规范，请参见短信签名规范。'],
        'isv.SIGN_FILE_LIMIT':['isv.SIGN_FILE_LIMIT','签名认证材料附件大小超过限制','原因：签名认证材料附件大小超过限制。解决方案：压缩签名认证材料至2 MB以下。'],
        'isv.SIGN_OVER_LIMIT':['isv.SIGN_OVER_LIMIT','签名字符数量超过限制','原因：签名的名称或申请说明的字数超过限制。解决方案：修改签名名称或申请说明，并重新提交审核。签名规范，请参见短信签名规范。'],
        'isv.TEMPLATE_OVER_LIMIT':['isv.TEMPLATE_OVER_LIMIT','模板字符数量超过限制','原因：模板的名称、内容或申请说明的字数超过限制。解决方案：修改模板的名称、内容或申请说明，并重新提交审核。'],
        'SIGNATURE_BLACKLIST':['SIGNATURE_BLACKLIST','签名内容涉及违规信息','原因：签名内容涉及违规信息。解决方案：重新修改签名内容。签名规范，请参见短信签名规范。'],
        'isv.SHORTURL_OVER_LIMIT':['isv.SHORTURL_OVER_LIMIT','超过单自然日短链申请数量上限','原因：一天创建短链数量超过限制。解决方案：合理预估一天申请短链数量，次日重新创建短链。更多信息，请参见短链服务。'],
        'isv.NO_AVAILABLE_SHORT_URL':['isv.NO_AVAILABLE_SHORT_URL','该账号无有效短链','原因：企业客户当前并无有效短链。解决方案：企业客户需重新申请有效短链，保证在短链有效期内调用短参生成接口。更多信息，请参见短链服务。'],
        'isv.SHORTURL_NAME_ILLEGAL':['isv.SHORTURL_NAME_ILLEGAL','短链名不能超过13字符','原因：短链名不能超过13个字符。解决方案：请根据短链规范重新创建。'],
        'isv.SOURCEURL_OVER_LIMIT':['isv.SOURCEURL_OVER_LIMIT','原始链接字符数量超过限制','原因：原始链接字符数量超过限制。解决方案：重新创建判断字符长度是否符合平台规则。'],
        'isv.SHORTURL_TIME_ILLEGAL':['isv.SHORTURL_TIME_ILLEGAL','短链有效期期限超过限制','原因：选择短链有效期限超过30天限制。解决方案：请保证短链有效期输入在30天以内。更多信息，请参见短链服务。'],
        'isv.PHONENUMBERS_OVER_LIMIT':['isv.PHONENUMBERS_OVER_LIMIT','上传手机号个数超过上限','原因：单次调用上传手机号个数超过50000个上限。解决方案：分多次调用短参生成接口，单次上传手机号个数不超过50000个。'],
        'isv.SHORTURL_STILL_AVAILABLE':['isv.SHORTURL_STILL_AVAILABLE','原始链接生成的短链仍在有效期内','原因：原始链接生成的短链仍在有效期内，无需重复创建。解决方案：待原始链接生成的短链失效或删除该短链后，重新创建。使用新的原始链接，创建新的短链。'],
        'isv.ERROR_EMPTY_FILE':['isv.ERROR_EMPTY_FILE','签名文件为空','原因：签名文件为空。解决方案：检查签名文件，补充签名资质证明、授权书等相关文件截图。相关操作，请参见添加签名。'],
        'isp.GATEWAY_ERROR':['isp.GATEWAY_ERROR','调用发送应用模块失败','原因：调用发送应用模块失败。解决方案：调用发送应用模块失败，请尝试重新发送。'],
        'isv.ERROR_SIGN_NOT_DELETE':['isv.ERROR_SIGN_NOT_DELETE','审核中的签名，暂时无法删除','原因：签名正在审核中，暂时无法删除。解决方案：请签名审核结束后，再删除对应签名。'],
        'isv.ERROR_SIGN_NOT_MODIFY':['isv.ERROR_SIGN_NOT_MODIFY','已通过的签名不支持修改','原因：已通过的签名不支持修改。解决方案：请按签名规范，重新提交申请签名。具体操作，请参见添加签名。'],
        'isv.ERROR_TEMPLATE_NOT_DELETE':['isv.ERROR_TEMPLATE_NOT_DELETE','审核中的模板，暂时无法删除','原因：模板正在审核中，暂时无法删除。解决方案：请模板审核结束后，再删除对应模板。'],
        'isv.ERROR_TEMPLATE_NOT_MODIFY':['isv.ERROR_TEMPLATE_NOT_MODIFY','已通过的模板不支持修改','原因：已通过的模板不支持修改。解决方案：请按模板规范，重新提交申请模板。更多信息，请参见公共内容规范。'],
        'isv.SMS_OVER_LIMIT':['isv.SMS_OVER_LIMIT','单日最多申请模板或签名100条','原因：您已超过单日最多申请模板或签名100条的上限。解决方案：请您24小时后继续申请，或点击链接，打开钉钉扫码进入短信服务专属钉群处理。'],
        'isv.CUSTOMER_REFUSED':['isv.CUSTOMER_REFUSED','用户已退订推广短信','原因：该手机用户已退订推广短信。解决方案：请尊重手机用户意愿，减少对该客户的推广短信发送。'],
        'isv.SMS_TEST_SIGN_TEMPLATE_LIMIT':['isv.SMS_TEST_SIGN_TEMPLATE_LIMIT','测试模板和签名限制','原因：测试专用签名和模板必须结合使用。解决方案：请使用短信服务提供的测试专用签名和测试专用模板。相关操作，请参见发送测试短信。'],
        'isv.SHORTURL_DOMAIN_EMPTY':['isv.SHORTURL_DOMAIN_EMPTY','短链创建失败','解决方案：请先提交该链接的一级域名报备。'],
        'template_parameter_count_illegal':['template_parameter_count_illegal','验证码模板仅支持一个验证码作为变量','原因：在验证码模板变量中只能定义一个参数。解决方案：请修改验证码模板变量。更多信息，请参见验证码模板规范。'],
        'isv.SMS_TEST_TEMPLATE_PARAMS_ILLEGAL':['isv.SMS_TEST_TEMPLATE_PARAMS_ILLEGAL','测试专用模板中的变量仅支持4~6位纯数字','解决方案：使用测试模板时变量仅支持传入4~6位纯数字。'],
        'isv.SMS_TEST_NUMBER_LIMIT':['isv.SMS_TEST_NUMBER_LIMIT','只能向已回复授权信息的手机号发送','解决方案：使用测试模板时仅支持向已授权信息的手机号发送。'],
        'isv.SMS_SIGN_EMOJI_ILLEGAL':['isv.SMS_SIGN_EMOJI_ILLEGAL','签名不能包含emoji表情','解决方案：签名中不支持使用emoji表情。'],
        'isv.SECURITY_FROZEN_ACCOUNT':['isv.SECURITY_FROZEN_ACCOUNT','因该账号长时间未使用，出于对您的账号安全考虑，已限制您账号的短信发送','您若需要继续使用该账号，请与您的商务经理联系。'],
        'IS_CLOSE':['IS_CLOSE','短信下发时通道被关停','阿里云会自动剔除被关停通道，建议稍候重试。'],
        'PARAMS_ILLEGAL':['PARAMS_ILLEGAL','参数错误','请检查短信签名、短信文案或手机号码等参数是否传入正确。'],
        'MOBILE_NOT_ON_SERVICE':['MOBILE_NOT_ON_SERVICE','停机、空号、暂停服务、关机、不在服务区','原因：运营商返回给阿里云平台该号码实时状态异常，如关机、停机、空号、暂停服务、不在服务区等。解决方案：请核实接受手机号码状态是否正常。'],
        'MOBILE_SEND_LIMIT':['MOBILE_SEND_LIMIT','单个号码日、月发送上限，流控超限，频繁发送超限','为了限制平台短信被恶意调用、在短时间内大量发起短信发送请求，阿里云平台和运营商都会有短信。'],
        'MOBILE_ACCOUNT_ABNORMAL':['MOBILE_ACCOUNT_ABNORMAL','用户账户异常、携号转网、欠费等','建议检查号码状态, 确保号码状态正常后重新尝试。'],
        'MOBILE_IN_BLACK':['MOBILE_IN_BLACK','手机号在黑名单','通常为手机号在运营商平台黑名单库中，一般是用户已退订此签名或命中运营商平台黑名单规则导致。'],
        'MOBLLE_TERMINAL_ERROR':['MOBLLE_TERMINAL_ERROR','手机终端问题、内存满、SIM卡满、非法设备等','建议核实终端设备状况、检查手机安全软件拦截记录、重启或者更换手机终端后再次尝试。'],
        'CONTENT_KEYWORD':['CONTENT_KEYWORD','内容关键字拦截','原因：运营商根据短信中有潜在风险或者高投诉的内容关键字进行自动拦截。解决方案：请您检查发送的短信内容并相应修改文案。'],
        'INVALID_NUMBER':['INVALID_NUMBER','号码状态异常','原因：短信接收号码状态异常，如关机、停机、空号、暂停服务、不在服务区或号码格式错误。解决方案：请您核实号码状态是否正常、号码格式是否正确。'],
        'CONTENT_ERROR':['CONTENT_ERROR','内容无退订','原因：营销内容中必需带退订信息。解决方案：请您核实文案内容，增加退订信息，在营销短信结尾添加“回T退订”。'],
        'REQUEST_SUCCESS':['REQUEST_SUCCESS','请求成功（平台接到请求，但未接收到运营商侧的回执状态）','原因：大概率是由于接收用户的状态异常导致。解决方案：请您检查接收用户状态是否正常。'],
        'SP_NOT_BY_INTER_SMS':['SP_NOT_BY_INTER_SMS','未开通国际短信','原因：收件人未开通接收国际短信功能。解决方案：联系运营商开通国际短信功能后再进行短信发送。'],
        'SP_UNKNOWN_ERROR':['SP_UNKNOWN_ERROR','运营商未知错误','该错误码表示阿里云平台接收到的运营商回执报告为未知错误，阿里云会定期与供应商核实更新。'],
        'USER_REJECT':['USER_REJECT','接收用户已退订此业务或产品未开通','建议将此类接收用户剔除出发送清单。'],
        'NO_ROUTE':['NO_ROUTE','当前短信内容无可用通道发送','发送的业务场景属于暂时无法支持的场景。'],
        'isv.UNSUPPORTED_CONTENT':['isv.UNSUPPORTED_CONTENT','不支持的短信内容','原因：短信内容包含不支持的发送内容，如短信内容中包含签名符号【】或包含非常规字符。解决方案：检查短信内容修改短信文案。短信模板内容规范，请参见公共内容规范。'],
    },
      table: {
        where: {
          phone: ''
        }
      }, // 表格配置
      choose: [], // 表格选中数据
      phone: '',
    }
  },
  methods: {
    // 搜索
    search() {

      this.$set(this.table, "url", '/sms/QuerySendDetails')
      this.$set(this.table, "where", {
        phone: this.phone
      })
      this.$refs.table.reload()
    }
  }
}
</script>

<style scoped>

</style>